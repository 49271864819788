<template>
	<VueApexCharts type="line" :height="height" :options="chartOptions" :series="series"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoLineDashboard",
	components: { VueApexCharts },
	props: [
		"dataSeries",
		"categories",
		"height",
		"money",
		"porcent",
		"dataLabels",
	],
	data: () => ({
		series: [],
		chartOptions: {
			chart: {
				type: "line",
				locales: [
					{
						name: "pt-BR",
						options: {
							shortMonths: [
								"Jan",
								"Fev",
								"Mar",
								"Abr",
								"Mai",
								"Jun",
								"Jul",
								"Ago",
								"Set",
								"Out",
								"Nov",
								"Dez",
							],
						},
					},
				],
				defaultLocale: "pt-BR",
				toolbar: {
					show: false,
				},
			},
			colors: ["#002D56", "#1764AA", "#45A5FC"],
			dataLabels: {
				enabled: true,
			},
			markers: {
				size: 5,
			},
			tooltip: { y: {} },
			stroke: {
				curve: "straight",
			},
			yaxis: {
				show: true,
			},
			xaxis: {
				categories: [],
			},
			legend: {
				position: "bottom",
			},
		},
	}),
  created() {
    if (this.dataLabels) {
      this.chartOptions.dataLabels.enabled = this.dataLabels;
      this.chartOptions.dataLabels.style = {
        colors: ["#000"], // Cor do texto dos labels (opcional)
      };
      // 📌 Evita que os labels sejam cortados nas laterais
      this.chartOptions.dataLabels.offsetX = 5;
      this.chartOptions.dataLabels.offsetY = -5;
      this.chartOptions.dataLabels.floating = true;
    }

    if (this.porcent) {
      this.chartOptions.tooltip.y = {
        enabled: true,
        formatter: function (val) {
          return `${parseFloat(val).toFixed(2)}%`;
        },
      };
      this.chartOptions.yaxis = {
        show: true,
        labels: {
          formatter: function (val) {
            return `${parseFloat(val).toFixed(2)}%`;
          },
        },
      };
    } else if (this.money) {
      this.chartOptions.tooltip.y = {
        enabled: true,
        formatter: function (val) {
          return parseFloat(val).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      };
      this.chartOptions.yaxis = {
        show: true,
        labels: {
          formatter: function (val) {
            return parseFloat(val).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
      };
      // 📌 Novo formatter para os dataLabels:
      this.chartOptions.dataLabels.formatter = function (val) {
        return parseFloat(val).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      };
    }

    // 📌 Adicionando padding para evitar cortes
    this.chartOptions.chart = {
      ...this.chartOptions.chart,
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    };

    this.series = this.dataSeries;
    this.chartOptions.xaxis.categories = this.categories;
  }
};
</script>

<style>
.apexcharts-tooltip {
	background: #ffffff !important;
	color: #023449;
	border-color: #023449 !important;
}

.apexcharts-tooltip-title {
	background-color: #023449 !important;
	color: #ffffff;
}
</style>
