<template>
  <div>
    <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" dark>
      <span class="font-weight-bold">{{snackbar.text}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
            class="font-weight-bold"
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.status = false"
        >Fechar</v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogFiliais" max-width="1224" :fullscreen="$vuetify.breakpoint.width<450" scrollable>
      <v-card elevation="0">
        <v-card-title class="primary white--text pt-4 pr-3" v-if="busca.mes">
          {{mesesNomes[busca.mes-1].titulo}} de {{busca.ano - 1}} X {{tituloDialog}} de {{busca.ano}}
          <v-spacer/>
          <v-btn color="white" icon @click="dialogFiliais = false" class="mt-n2 mr-n1"> <v-icon> mdi-close </v-icon> </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-0">
          <v-simple-table v-if="$vuetify.breakpoint.mdAndUp" dense fixed-header :height="busca.idfiliais.length>15?'70vh':''">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-truncate">ID</th>
                <th class="text-truncate">Filial</th>
                <th class="text-center">Qtde de Tickets A.A</th>
                <th class="text-center">Qtde de Tickets Mês Atual</th>
                <th class="text-center">% Var. Tickets</th>
                <th class="text-center">Qtde de Clientes A.A</th>
                <th class="text-center">Qtde de Clientes Mês Atual</th>
                <th class="text-center">% Var. Clientes</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="dadosFiliais">
                <tr v-for="(u, i) in dadosFiliais" :key="i">
                  <td>{{u.idempresa}}</td>
                  <td>{{u.empresa}}</td>
                  <td class="text-center">{{u.qtd_vendas_aa}}</td>
                  <td class="text-center">{{u.qtd_vendas }}</td>
                  <td class="text-center font-weight-medium" :class="u.var_qtd_vendas_aa > 0 ? 'blue--text' : 'red--text'">{{u.var_qtd_vendas_aa }} %</td>
                  <td class="text-center">{{u.qtd_clientes_aa }}</td>
                  <td class="text-center">{{u.qtd_clientes }}</td>
                  <td class="text-center font-weight-medium" :class="u.var_qtd_vendas_aa > 0 ? 'blue--text': 'red--text'">{{u.var_qtd_clientes_aa }} %</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="text-center" colspan="6">Nenhum registro encontrado.</td>
                </tr>
              </template>
              </tbody>
              <tfoot style="background-color: #f4f4f4; position: sticky; bottom: 0; z-index: 2;">
              <tr class="font-weight-bold">
                <td colspan="2" class="text-right" style="height:40px;">Total:</td>
                <td class="text-center">{{  dadosFiliais.reduce((sum, u) => sum + u.qtd_vendas_aa, 0) }} </td>
                <td class="text-center">{{  dadosFiliais.reduce((sum, u) => sum + (u.qtd_vendas || 0), 0) }} </td>
                <td
                    class="text-center"
                    :class="((dadosFiliais.reduce((sum, u) => sum + (u.qtd_vendas || 0), 0) - dadosFiliais.reduce((sum, u) => sum + (u.qtd_vendas_aa || 0), 0)) / dadosFiliais.reduce((sum, u) => sum + (u.qtd_vendas_aa || 0), 0)*100) > 0 ? 'blue--text': 'red--text'"
                >
                  {{  ((dadosFiliais.reduce((sum, u) => sum + (u.qtd_vendas || 0), 0)
                    - dadosFiliais.reduce((sum, u) => sum + (u.qtd_vendas_aa || 0), 0))
                    / dadosFiliais.reduce((sum, u) => sum + (u.qtd_vendas_aa || 0), 0)*100).toFixed(2) }} %
                </td>
                <td class="text-center">{{  dadosFiliais.reduce((sum, u) => sum + (u.qtd_clientes_aa || 0), 0) }} </td>
                <td class="text-center">{{  dadosFiliais.reduce((sum, u) => sum + (u.qtd_clientes || 0), 0) }} </td>
                <td class="text-center" :class="((dadosFiliais.reduce((sum, u) => sum + (u.qtd_clientes || 0), 0) - dadosFiliais.reduce((sum, u) => sum + (u.qtd_clientes_aa || 0), 0)) / dadosFiliais.reduce((sum, u) => sum + (u.qtd_clientes_aa || 0), 0) *100) > 0 ? 'blue--text': 'red--text'">
                  {{  ((dadosFiliais.reduce((sum, u) => sum + (u.qtd_clientes || 0), 0) - dadosFiliais.reduce((sum, u) => sum + (u.qtd_clientes_aa || 0), 0)) / dadosFiliais.reduce((sum, u) => sum + (u.qtd_clientes_aa || 0), 0) *100).toFixed(2) }} %
                </td>
              </tr>
              </tfoot>
            </template>
          </v-simple-table>
          <v-row v-else-if="false">
            <v-col v-for="(item, index) in dadosFiliais" :key="index" cols="12" class="pb-0">
              <v-card>
                <v-card-title class="text-body-1 font-weight-bold">{{ item.idempresa }}</v-card-title>
                <v-card-subtitle class="pb-1">Cód: {{ item.empresa }}</v-card-subtitle>
                <v-card-text>
                  <p class="mb-0"><strong>Qtde:</strong> {{ item.qtd_vendas_aa }}</p>
                  <p class="mb-0"><strong>Valor:</strong> {{ item.qtd_vendas | formataDinheiro }}</p>
                  <p class="mb-0"><strong>Vendedor:</strong> {{ item.vendedor | formataTextoPedido }}</p>
                  <p class="mb-0"><strong>Local de Estoque:</strong> {{ item.localretirada }}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- FILTROS -->
    <v-card elevation="1">
      <v-card-title>
        <v-row no-gutters>
          <v-col class="pr-2">
            <v-select
                :items="filiaisFiltro"
                item-text="filial"
                item-value="idfilial"
                label="Filial"
                outlined
                dense
                v-model="busca.idfiliais"
                hide-details
                multiple
                class="single-line-selection"
                @change="listar()"
            >
              <template v-slot:selection="{ item, index }">
                <template v-if="selectAllChecked">
                  <span v-if="index === 0">Todas filiais</span>
                </template>
                <template v-else>
                  <span v-if="index === 0" class="mr-1">{{ item.filial }}</span>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ busca.idfiliais.length - 1 }})
                  </span>
                </template>
              </template>

              <template #prepend-item>
                <v-list-item
                    class="pr-0"
                    dense
                    :style="{
                      backgroundColor: selectAllChecked ? '#D0D6D9' : 'transparent'
                    }"
                >
                  <v-list-item-action>
                    <v-checkbox v-model="selectAllChecked" @click.stop="toggleSelectAll('CHECKBOX')"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content @click="toggleSelectAll('LABEL')">
                    <label class="text-subtitle-2">Todas filiais</label>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col class="px-1">
            <v-autocomplete
                :disabled="carregando"
                dense
                outlined
                :items="anos"
                v-model="busca.ano"
                @change="listar()"
                hide-details
            >
            </v-autocomplete>
          </v-col>

<!--          <v-col cols="auto">-->
<!--            <v-btn-->
<!--                :disabled="carregando"-->
<!--                class="mt-1 ml-2"-->
<!--                color="primary"-->
<!--                elevation="0"-->
<!--                fab-->
<!--                x-small-->
<!--                @click="listar()"-->
<!--            >-->
<!--              <v-icon>mdi-magnify</v-icon>-->
<!--            </v-btn>-->
<!--          </v-col>-->
        </v-row>
      </v-card-title>
    </v-card>

    <!-- CARD MESES -->
    <v-card class="mt-5" dark color="primary" elevation="1">
      <v-card-title class="px-3">
        <v-row>
          <v-col>
            {{$vuetify.breakpoint.smAndDown ? 'Desempenho de Vendas' : 'Desempenho Mensal de Vendas'}}
            - {{busca.ano}}
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" class="py-sm-0 pt-0 pb-3 justify-center align-center align-content-center" cols="12" xl="auto" lg="auto" md="auto" sm="auto">
<!--            <v-menu offset-y>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn-->
<!--                    light-->
<!--                    :loading="carregando"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                    small-->
<!--                    :class="{'active-btn': graficoSelecionado === 'table' ||  graficoSelecionado === 'table-coluna'}"-->
<!--                    elevation="0"-->
<!--                >-->
<!--                  <v-icon>mdi-table</v-icon> &lt;!&ndash; Ícone genérico para tabelas &ndash;&gt;-->
<!--                </v-btn>-->
<!--              </template>-->

<!--              <v-list dense>-->
<!--                <v-list-item @click="selecionarGrafico('table-coluna')">-->
<!--                  <v-list-item-icon class="mr-5">-->
<!--                    <v-icon>mdi-table-column</v-icon>-->
<!--                  </v-list-item-icon>-->
<!--                  <v-list-item-content>-->
<!--                    <v-list-item-title>Indicadores por coluna</v-list-item-title>-->
<!--                  </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item @click="selecionarGrafico('table')">-->
<!--                  <v-list-item-icon class="mr-5">-->
<!--                    <v-icon>mdi-table-row</v-icon>-->
<!--                  </v-list-item-icon>-->
<!--                  <v-list-item-content>-->
<!--                    <v-list-item-title>Indicadores por linha</v-list-item-title>-->
<!--                  </v-list-item-content>-->
<!--                </v-list-item>-->
<!--              </v-list>-->
<!--            </v-menu>-->

            <v-btn
                light
                :loading="carregando"
                @click="selecionarGrafico('table-coluna')"
                :class="{'active-btn': graficoSelecionado === 'table-coluna'}"
                small
                elevation="0"
            >
              <v-icon>mdi-table</v-icon>
            </v-btn>

            <v-btn
                light
                :loading="carregando"
                @click="selecionarGrafico('pie')"
                :class="{'active-btn': graficoSelecionado === 'pie'}"
                small
                elevation="0"
                class="ml-2"
            >
              <v-icon>mdi-chart-pie</v-icon>
            </v-btn>

            <v-btn
                light
                :loading="carregando"
                @click="selecionarGrafico('line')"
                :class="{'active-btn': graficoSelecionado === 'line'}"
                small
                elevation="0"
                class="ml-2"
            >
              <v-icon>mdi-chart-line</v-icon>
            </v-btn>

            <v-btn
                light
                :loading="carregando"
                @click="selecionarGrafico('bar')"
                :class="{'active-btn': graficoSelecionado === 'bar'}"
                small
                elevation="0"
                class="ml-2"
            >
              <v-icon>mdi-chart-bar</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-progress-linear small indeterminate absolute :active="carregando" />
      <v-divider/>

      <!-- VISÃO PC -->
      <v-card-text class="pa-3" v-if="$vuetify.breakpoint.mdAndUp">
<!--        <v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />-->

        <!-- TABLE -->
        <v-simple-table v-if="graficoSelecionado==='table'" light dense class="rounded-0 bordered-table">
          <template v-slot:default>
            <thead>
            <tr class="grey lighten-1">
              <th class="text-left px-2" style="height: 42px !important;">Indicador</th>
              <th
                  class="text-left px-0"
                  v-for="(mes, index) in mesesNomes"
                  :key="index"
                  width="7%"
              >
                <v-hover v-slot="{ hover }">
                  <div
                      class="d-flex align-center justify-space-between w-100 pl-2 pr-2 py-2"
                      :style="{ backgroundColor: hover ? '#E0E0E0' : 'transparent', cursor: 'pointer' }"
                      @click="detalharMes(mes)"
                  >
                    <span>{{ mes.titulo }}</span>
                    <v-icon v-if="hover">mdi-magnify-plus-outline</v-icon>
                  </div>
                </v-hover>
              </th>
              <th class="px-2">
                Total
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-left px-2" v-for="(indicador, index) in indicadoresNomes" :key="index">
              <td class="px-2 text-caption font-weight-medium">{{indicador.titulo}}</td>
              <td class="px-2" v-for="mes in mesesNomes" :key="mes">{{ (indicador.valor === 'valor_vendas' || indicador.valor === 'ticket_medio') ?
                  formataDinheiro(dados[indicador.valor][mes.valor]) : dados[indicador.valor][mes.valor] || '-' }}
              </td>
              <td class="px-2">{{ (indicador.valor === 'valor_vendas' || indicador.valor === 'ticket_medio') ?
                  formataDinheiro(Object.values(dados[indicador.valor]).reduce((total, valor) => total + valor, 0)) : Object.values(dados[indicador.valor]).reduce((total, valor) => total + valor, 0)  }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table v-else-if="graficoSelecionado==='table-coluna'" light dense class="rounded-0 bordered-table">
          <template v-slot:default>
            <thead>
            <tr class="grey lighten-1">
              <th class="text-left px-2" width="14%" style="height: 38px !important;">Mês</th>
              <th class="text-left px-2" v-for="(indicador, index) in indicadoresNomes" :key="index">
                {{ indicador.titulo }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(mes, index) in mesesNomes" :key="index">
              <td class="px-0 text-caption font-weight-medium">
                <v-hover v-slot="{ hover }">
                  <div
                      class="d-flex align-center justify-space-between w-100 pl-2 pr-2 py-2"
                      :style="{ backgroundColor: hover ? '#E0E0E0' : 'transparent', cursor: 'pointer' }"
                      @click="detalharMes(mes)"
                  >
                    <span>{{ mes.titulo }}</span>
                    <v-icon v-if="hover">mdi-magnify-plus-outline</v-icon>
                    <v-icon v-else size="20">mdi-magnify-plus-outline</v-icon>
                  </div>
                </v-hover>
              </td>
              <td class="px-2">{{ dados.qtd_vendas[mes.valor] || '-' }}</td>
              <td class="px-2">{{ (dados.valor_vendas[mes.valor] ? formataDinheiro(dados.valor_vendas[mes.valor]) : '-')  }}</td>
              <td class="px-2">{{ (dados.ticket_medio[mes.valor] ? formataDinheiro(dados.ticket_medio[mes.valor]) : '-') }}</td>
              <td class="px-2">{{ dados.qtd_clientes[mes.valor] || '-'}}</td>
              <td class="px-2">{{ dados.media_ticket_clientes[mes.valor] ? dados.media_ticket_clientes[mes.valor].toFixed(2) : '-'}}</td>
            </tr>
            </tbody>
            <tfoot class="grey lighten-4">
              <td class="px-2 font-weight-medium">Total Geral</td>
              <td class="px-2 font-weight-medium">{{ dados.total_qtd_vendas }}</td>
              <td class="px-2 font-weight-medium">{{ dados.total_valor_vendas | formataDinheiro }}</td>
              <td class="px-2 font-weight-medium">{{ dados.total_ticket_medio | formataDinheiro }}</td>
              <td class="px-2 font-weight-medium">{{ dados.total_qtd_clientes }}</td>
              <td class="px-2 font-weight-medium">{{ dados.total_media_ticket_clientes ? dados.total_media_ticket_clientes.toFixed(2) : '-' }}</td>
            </tfoot>
          </template>
        </v-simple-table>

        <!-- SELEÇÃO DO INDICADOR -->
        <v-card class="align-content-center align-center" light v-else max-height="500px">
          <v-card-subtitle v-if="!indicadorSelecionado" class="justify-center text-center mt-12">
            Selecione um indicador:
          </v-card-subtitle>

          <!-- EXIBIÇÃO DE GRAFICOS -->
          <v-card-text v-if="indicadorSelecionado">
            <GraficoPizzaMarketingTickets
                v-if="graficoSelecionado==='pie'"
                :key="JSON.stringify(pieSeries)"
                :dataSeries="pieSeries"
                :labels="meses"
                height="315"
            />

            <GraficoLinhasMarketingTickets
                v-if="graficoSelecionado==='line'"
                :dataSeries="lineSeries"
                :key="JSON.stringify(lineSeries)"
                :categories="mesesNomes.map(item => item.titulo)"
                height="300"
                :money="(indicadorSelecionado === 'ticket_medio' || indicadorSelecionado === 'valor_vendas')"
                :porcent="false"
            />

            <GraficoBarrasMarketingTickets
                v-if="graficoSelecionado==='bar'"
                :key="JSON.stringify(barSeries)"
                :dataSeries="barSeries"
                :categories="mesesNomes.map(item => item.titulo)"
                height="300"
                :money="(indicadorSelecionado === 'ticket_medio' || indicadorSelecionado === 'valor_vendas')"
                :porcentagem="false"
                :distributed="true"
                :dataLabels="{
                                      position: 'top',
                                      orientation: 'horizontal',
                                      offsetY: -25,
                                  }"
            />
          </v-card-text>

          <v-divider v-if="!indicadorSelecionado" class="mt-12"/>
          <v-divider v-if="indicadorSelecionado"/>
          <v-card-actions class="justify-space-around my-1">
            <v-btn :class="{'active-btn2': indicadorSelecionado === 'qtd_vendas'}" :small="$vuetify.breakpoint.mdAndDown" color="primary" outlined @click="selecionarIndicador('qtd_vendas')" width="17%">
              <v-icon class="mr-1" :small="$vuetify.breakpoint.mdAndDown">mdi-counter</v-icon>
              Qtde de vendas
            </v-btn>

            <v-btn :class="{'active-btn2': indicadorSelecionado === 'valor_vendas'}" :small="$vuetify.breakpoint.mdAndDown" color="primary" outlined @click="selecionarIndicador('valor_vendas')" width="20%">
              <v-icon class="mr-1" :small="$vuetify.breakpoint.mdAndDown">mdi-cash</v-icon>
              Valor de vendas (R$)
            </v-btn>

            <v-btn :class="{'active-btn2': indicadorSelecionado === 'ticket_medio'}" :small="$vuetify.breakpoint.mdAndDown" color="primary" outlined @click="selecionarIndicador('ticket_medio')" width="14%">
              <v-icon class="mr-1" :small="$vuetify.breakpoint.mdAndDown">mdi-receipt</v-icon>
              Ticket médio
            </v-btn>

            <v-btn :class="{'active-btn2': indicadorSelecionado === 'qtd_clientes'}" :small="$vuetify.breakpoint.mdAndDown" color="primary" outlined @click="selecionarIndicador('qtd_clientes')" width="18%">
              <v-icon class="mr-1" :small="$vuetify.breakpoint.mdAndDown">mdi-account-group</v-icon>
              Qtde de clientes
            </v-btn>

            <v-btn :class="{'active-btn2': indicadorSelecionado === 'media_ticket_clientes'}" :small="$vuetify.breakpoint.mdAndDown" color="primary" outlined @click="selecionarIndicador('media_ticket_clientes')" width="27%">
              <v-icon class="mr-1" :small="$vuetify.breakpoint.mdAndDown">mdi-calculator</v-icon>
              Média de tickets por cliente
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>

      <!-- VISÃO DISPOSITIVOS MÓVEIS -->
      <v-card-text class="pa-3" v-else>
<!--        <v-card elevation="0" flat style="background-color: transparent !important;" light>-->
<!--          <v-container class="pb-0">-->
<!--            <v-card-text class="elevation-0 pa-0">-->
<!--              <v-row v-for="(mes, index) in mesesNomes" :key="index" class="mb-1">-->
<!--                <v-col cols="12" class="pa-0">-->
<!--                  <v-card rounded="0">-->
<!--                    <v-card-subtitle class="text-center pa-0 font-weight-bold" style="background-color: #f4f4f4">-->
<!--                      <v-hover v-slot="{ hover }">-->
<!--                        <div-->
<!--                            class="w-100 pl-2 pr-2 py-2"-->
<!--                            :style="{ backgroundColor: hover ? '#E0E0E0' : 'transparent', cursor: 'pointer' }"-->
<!--                            @click="detalharMes(mes)"-->
<!--                        >-->
<!--                          <span>{{ mes.titulo }}</span>-->
<!--                          <v-icon class="ml-1 mr-n6">mdi-magnify-plus-outline</v-icon>-->
<!--                        </div>-->
<!--                      </v-hover>-->
<!--                    </v-card-subtitle>-->
<!--                    <v-divider/>-->
<!--                    <v-card-text class="px-3 py-2 text-caption">-->
<!--                      <v-row>-->
<!--                        <v-col cols="6" class="text-center bordered-col mt-1">-->
<!--                          <strong> Qtde de Tickets: </strong> <v-spacer/> {{dados.qtd_vendas[mes.valor] || '-'}} <br/>-->
<!--                        </v-col>-->
<!--                        <v-col cols="6" class="text-center bordered-col mt-1">-->
<!--                          <strong> Qtde de Clientes: </strong> <v-spacer/>{{ dados.qtd_clientes[mes.valor] || '-' }}<br/>-->
<!--                        </v-col>-->
<!--                        <v-col cols="6" class="text-center bordered-col">-->
<!--                          <strong> Valor de Vendas: </strong> <v-spacer/> {{ (dados.valor_vendas[mes.valor] ? formataDinheiro(dados.valor_vendas[mes.valor]) : '-') }} <br/>-->
<!--                        </v-col>-->
<!--                        <v-col cols="6" class="text-center bordered-col">-->
<!--                          <strong> Ticket Médio: </strong> <v-spacer/>{{ (dados.ticket_medio[mes.valor] ? formataDinheiro(dados.ticket_medio[mes.valor]) : '-') }}<br/>-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" class="text-center bordered-col mb-1">-->
<!--                          <strong> Média de Ticket por Clientes: </strong> <v-spacer/>{{ dados.media_ticket_clientes[mes.valor] ? dados.media_ticket_clientes[mes.valor].toFixed(2) : '-' }}-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </v-card-text>-->
<!--                  </v-card>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-card-text>-->
<!--          </v-container>-->
<!--        </v-card>-->
      </v-card-text>

      <v-progress-linear indeterminate absolute :active="carregando" />
    </v-card>


<!--    <VueApexCharts v-if="graficoSelecionado==='pie' && indicadorSelecionado" type="pie" :options="pieOptions" :series="pieSeries"></VueApexCharts>-->


    <!-- CARD TOTAIS -->
<!--    <v-card color="primary" dark class="mt-5" elevation="1">-->
<!--      <v-card-title class="pa-3">-->
<!--        Total por Indicador - {{busca.data}}-->
<!--      </v-card-title>-->
<!--      <v-divider/>-->
<!--      <v-card-text class="pa-2">-->
<!--        <v-row no-gutters>-->
<!--          <v-col class="bordered-col" :cols="$vuetify.breakpoint.smAndDown ? 6 : undefined">-->
<!--            <v-card light elevation="0" rounded="0">-->
<!--              <v-card-subtitle class="text-caption py-1">-->
<!--                Quantidade de vendas-->
<!--              </v-card-subtitle>-->
<!--              <v-divider/>-->
<!--              <v-card-text class="text-center text-h6 pa-1">-->
<!--                <template v-if="true">-->
<!--                  {{ 0 }}-->
<!--                </template>-->
<!--                <template v-else>{{ 0 | formataDinheiro }} / 0</template>-->
<!--              </v-card-text>-->
<!--            </v-card>-->
<!--          </v-col>-->
<!--          <v-col class="bordered-col" :cols="$vuetify.breakpoint.smAndDown ? 6 : undefined">-->
<!--            <v-card light elevation="0" rounded="0">-->
<!--              <v-card-subtitle class="text-caption py-1">-->
<!--                Valor de vendas (R$)-->
<!--              </v-card-subtitle>-->
<!--              <v-divider/>-->
<!--              <v-card-text class="text-center text-h6 pa-1">-->
<!--                <template v-if="true">-->
<!--                  {{ 0 | formataDinheiro }}-->
<!--                </template>-->
<!--                <template v-else>{{ 0 | formataDinheiro }} / 0</template>-->
<!--              </v-card-text>-->
<!--            </v-card>-->
<!--          </v-col>-->
<!--          <v-col class="bordered-col" :cols="$vuetify.breakpoint.smAndDown ? 6 : undefined">-->
<!--            <v-card light elevation="0" rounded="0">-->
<!--              <v-card-subtitle class="text-caption py-1">-->
<!--                Ticket médio-->
<!--              </v-card-subtitle>-->
<!--              <v-divider/>-->
<!--              <v-card-text class="text-center text-h6 pa-1">-->
<!--                <template v-if="true">-->
<!--                  {{ 0 | formataDinheiro }}-->
<!--                </template>-->
<!--                <template v-else>{{ 0 | formataDinheiro }} / 0</template>-->
<!--              </v-card-text>-->
<!--            </v-card>-->
<!--          </v-col>-->
<!--          <v-col class="bordered-col" :cols="$vuetify.breakpoint.smAndDown ? 6 : undefined">-->
<!--            <v-card light elevation="0" rounded="0">-->
<!--              <v-card-subtitle class="text-caption py-1">-->
<!--                Qtde de clientes-->
<!--              </v-card-subtitle>-->
<!--              <v-divider/>-->
<!--              <v-card-text class="text-center text-h6 pa-1">-->
<!--                <template v-if="true">-->
<!--                  {{ 0 }}-->
<!--                </template>-->
<!--                <template v-else>{{ 0 | formataDinheiro }} / 0</template>-->
<!--              </v-card-text>-->
<!--            </v-card>-->
<!--          </v-col>-->
<!--          <v-col class="bordered-col" :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">-->
<!--            <v-card light elevation="0" rounded="0">-->
<!--              <v-card-subtitle class="text-caption text-truncate py-1">-->
<!--                Média de tickets por cliente-->
<!--              </v-card-subtitle>-->
<!--              <v-divider/>-->
<!--              <v-card-text class="text-center text-h6 pa-1">-->
<!--                <template v-if="true">-->
<!--                  {{ 0 }}-->
<!--                </template>-->
<!--                <template v-else>{{ 0 | formataDinheiro }} / 0</template>-->
<!--              </v-card-text>-->
<!--            </v-card>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-card-text>-->
<!--    </v-card>-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import GraficoPizzaMarketingTickets from "../Widgets/GraficoPizzaMarketingTickets.vue";
import GraficoLinhasMarketingTickets from "@/Components/Widgets/GraficoLinhasMarketingTickets.vue";
import GraficoBarrasMarketingTickets from "@/Components/Widgets/GraficoBarrasMarketingTickets.vue";

export default {
  name: "ContratosalvarCadastro",
  components: {GraficoBarrasMarketingTickets, GraficoLinhasMarketingTickets, GraficoPizzaMarketingTickets },
  mixins: [mixinFilial],
  data: () => ({
    dialogFiliais:false,
    selectAllChecked: false,

    graficoSelecionado: 'table-coluna',
    indicadorSelecionado: null,

    tituloDialog: '',

    anos: Array.from({ length: new Date().getFullYear() - 2022 + 1 }, (_, i) => 2022 + i).reverse(),

    meses: ["janeiro", "fevereiro", "marco", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],

    mesesNomes: [
      { id: 1, titulo:"Janeiro", valor: "janeiro" },
      { id: 2, titulo:"Fevereiro", valor: "fevereiro" },
      { id: 3, titulo:"Março", valor: "marco" },
      { id: 4, titulo:"Abril", valor: "abril" },
      { id: 5, titulo:"Maio", valor: "maio" },
      { id: 6, titulo:"Junho", valor: "junho" },
      { id: 7, titulo:"Julho", valor: "julho" },
      { id: 8, titulo:"Agosto", valor: "agosto" },
      { id: 9, titulo:"Setembro", valor: "setembro" },
      { id: 10, titulo:"Outubro", valor: "outubro" },
      { id: 11, titulo:"Novembro", valor: "novembro" },
      { id: 12, titulo:"Dezembro", valor: "dezembro" }
    ],

    indicadoresNomes: [
      { titulo: "Qtde de Tickets", valor: "qtd_vendas" },
      { titulo: "Valor de Vendas", valor: "valor_vendas" },
      { titulo: "Ticket Médio", valor: "ticket_medio" },
      { titulo: "Qtde de Clientes", valor: "qtd_clientes" },
      { titulo: "Média de Ticket por Clientes", valor: "media_ticket_clientes" }
    ],

    dados: {
      qtd_vendas: {},
      valor_vendas: {},
      ticket_medio: {},
      qtd_clientes: {},
      media_ticket_clientes: {}
    },

    dadosFiliais: [],

    pieOptions: {
      chart: { type: 'pie', height: 200 },
      labels: [],
    },
    pieSeries: [],
    lineOptions: {
      chart: { type: 'line', height: 200 },
      xaxis: { categories: [] },
    },
    lineSeries: [],
    barOptions: {
      chart: { type: 'bar', height: 200 },
      xaxis: { categories: [] },
    },
    barSeries: [],

    snackbar: { status: false, timeout: 10000, text: 'Erro: tente recarregar a pagina!' , color: 'red'},

    carregando: false,
    carregandoSkeleton: false,

    busca: {
      ano: new Date().getFullYear(),
      mes: null,
      idfiliais: null,
    },
  }),
  watch: {
    'busca.idfiliais'(newValue) {
      if (newValue.length === 0 || newValue.length !== this.filiaisFiltro.length) {
        this.selectAllChecked = false;
      } else {
        this.selectAllChecked = true;
      }
    }
  },
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
              v.idfilial !== 4 &&
              v.idfilial !== 6 &&
              v.idfilial !== 12 &&
              v.idfilial !== 13 &&
              v.idfilial !== 25 &&
              v.idfilial !== 28 &&
              v.idfilial !== 29 &&
              v.idfilial !== 33 &&
              v.idfilial !== 37 &&
              v.idfilial !== 40 &&
              v.idfilial !== 42
          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    toggleSelectAll(tipo) {
      if (tipo == 'CHECKBOX') {
        if (this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
          this.listar();
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
      } else {
        if (!this.selectAllChecked) {
          // Se a opção "Selecionar Todos" estiver desmarcada, selecionar todas as filiais
          this.busca.idfiliais = this.filiaisFiltro.map(item => item.idfilial);
          this.listar();
        } else {
          // Se a opção "Selecionar Todos" estiver marcada, limpar todas as seleções
          this.busca.idfiliais = [];
        }
        this.selectAllChecked = !this.selectAllChecked;
      }
    },

    selecionarGrafico(tipo) {
      this.graficoSelecionado = tipo;
      if(tipo==='table'){
        this.indicadorSelecionado = null;
      }
    },

    selecionarIndicador(indicador) {
      this.indicadorSelecionado = indicador;
      this.updateCharts();
    },

    detalharMes(mes) {
      this.tituloDialog = mes.titulo;
      this.busca.mes = mes.id;
      this.dialogFiliais=true;
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}dashboard/marketing/tickets/mes/listar`, {
            idfilial: this.busca.idfiliais.join(',') || null,
            ano: this.busca.ano.toString() || null,
            mes: this.busca.mes.toString(),
          })
          .then((res) => {
            this.dadosFiliais = res.data;
          })
          .catch((e) => {
            this.dadosFiliais = [
              { idempresa: 1, empresa: "Matriz", qtd_vendas_aa: 2152, qtd_clientes_aa:  984, qtd_vendas:  2015, qtd_clientes:  943, var_qtd_vendas_aa: -6.37, var_qtd_clientes_aa: -4.17 },
              { idempresa: 2, empresa: "Pitanga", qtd_vendas_aa: 3104, qtd_clientes_aa:  1746, qtd_vendas:  2962, qtd_clientes:  1676, var_qtd_vendas_aa: -4.57, var_qtd_clientes_aa: -4.01 },
              { idempresa: 3, empresa: "São João", qtd_vendas_aa: 2550, qtd_clientes_aa:  1244, qtd_vendas:  2074, qtd_clientes:  987, var_qtd_vendas_aa: -18.67, var_qtd_clientes_aa: -20.66 },
              { idempresa: 5, empresa: "Cândido", qtd_vendas_aa: 1016, qtd_clientes_aa:  432, qtd_vendas:  1322, qtd_clientes:  616, var_qtd_vendas_aa:  30.12, var_qtd_clientes_aa:   42.59 },
              { idempresa: 7, empresa: "Apucarana", qtd_vendas_aa: 1531, qtd_clientes_aa:  702, qtd_vendas:  878, qtd_clientes:  470, var_qtd_vendas_aa: -42.65, var_qtd_clientes_aa: -33.05 },
              { idempresa: 8, empresa: "Jardim alegre", qtd_vendas_aa: 1626, qtd_clientes_aa:  463, qtd_vendas:  1506, qtd_clientes:  737, var_qtd_vendas_aa: -7.38, var_qtd_clientes_aa:   59.18 },
              { idempresa: 9, empresa: "Manoel ribas", qtd_vendas_aa:985, qtd_clientes_aa:  461, qtd_vendas:  1395, qtd_clientes:  480, var_qtd_vendas_aa:  41.62, var_qtd_clientes_aa:   4.12 },
              { idempresa: 10, empresa: "Faxinal", qtd_vendas_aa: 1934, qtd_clientes_aa:  775, qtd_vendas:  920, qtd_clientes:  434, var_qtd_vendas_aa: -52.43, var_qtd_clientes_aa: -44.00 },
              { idempresa: 11, empresa: "Campo mourão", qtd_vendas_aa: 5313, qtd_clientes_aa:  2613, qtd_vendas:  1733, qtd_clientes:  706, var_qtd_vendas_aa: -67.38, var_qtd_clientes_aa: -72.98 },
              { idempresa: 14, empresa: "Cianorte", qtd_vendas_aa: 2148, qtd_clientes_aa:  1082, qtd_vendas:  230, qtd_clientes:  221, var_qtd_vendas_aa: -89.29, var_qtd_clientes_aa: -79.57 },
              { idempresa: 15, empresa: "Paranavaí", qtd_vendas_aa: 2194, qtd_clientes_aa:  1152, qtd_vendas:  1762, qtd_clientes:  999, var_qtd_vendas_aa: -19.69, var_qtd_clientes_aa: -13.28 },
              { idempresa: 16, empresa: "Telêm Borba", qtd_vendas_aa: 3531, qtd_clientes_aa:  2113, qtd_vendas:  2179, qtd_clientes:  1135, var_qtd_vendas_aa: -38.29, var_qtd_clientes_aa: -46.28 },
              { idempresa: 17, empresa: "Roncador", qtd_vendas_aa:870, qtd_clientes_aa:  463, qtd_vendas:  3057, qtd_clientes:  1875, var_qtd_vendas_aa:  251.38, var_qtd_clientes_aa:   304.97 },
              { idempresa: 18, empresa: "Guarapuava", qtd_vendas_aa: 4342, qtd_clientes_aa:  2116, qtd_vendas:  783, qtd_clientes:  448, var_qtd_vendas_aa: -81.97, var_qtd_clientes_aa: -78.83 },
              { idempresa: 19, empresa: "Palmital", qtd_vendas_aa: 1734, qtd_clientes_aa:  848, qtd_vendas:  3613, qtd_clientes:  1875, var_qtd_vendas_aa:  108.36, var_qtd_clientes_aa:   121.11 },
              { idempresa: 20, empresa: "Ibiporã", qtd_vendas_aa: 1485, qtd_clientes_aa:  897, qtd_vendas:  1455, qtd_clientes:  771, var_qtd_vendas_aa: -2.02, var_qtd_clientes_aa: -14.05 },
              { idempresa: 21, empresa: "Turvo", qtd_vendas_aa: 1299, qtd_clientes_aa:  678, qtd_vendas:  1384, qtd_clientes:  827, var_qtd_vendas_aa:  6.54, var_qtd_clientes_aa:   21.98 },
              { idempresa: 23, empresa: "São Mateus", qtd_vendas_aa: 3544, qtd_clientes_aa:  1683, qtd_vendas:  976, qtd_clientes:  536, var_qtd_vendas_aa: -72.46, var_qtd_clientes_aa: -68.15 },
              { idempresa: 24, empresa: "Triunfo", qtd_vendas_aa: 1455, qtd_clientes_aa:  568, qtd_vendas:  2985, qtd_clientes:  1434, var_qtd_vendas_aa:  105.15, var_qtd_clientes_aa:   152.46 },
              { idempresa: 26, empresa: "Pontal", qtd_vendas_aa: 2473, qtd_clientes_aa:  734, qtd_vendas:  1367, qtd_clientes:  599, var_qtd_vendas_aa: -44.72, var_qtd_clientes_aa: -18.39 },
              { idempresa: 27, empresa: "Mandaguari", qtd_vendas_aa: 2155, qtd_clientes_aa:  1172, qtd_vendas:  1803, qtd_clientes:  577, var_qtd_vendas_aa: -16.33, var_qtd_clientes_aa: -50.77 },
              { idempresa: 30, empresa: "Cambé", qtd_vendas_aa: 1157, qtd_clientes_aa:  764, qtd_vendas:  1962, qtd_clientes:  1121, var_qtd_vendas_aa:  69.58, var_qtd_clientes_aa:   46.73 },
              { idempresa: 31, empresa: "Reserva", qtd_vendas_aa: 1598, qtd_clientes_aa:  840, qtd_vendas:  860, qtd_clientes:  544, var_qtd_vendas_aa: -46.18, var_qtd_clientes_aa: -35.24 },
              { idempresa: 35, empresa: "Rolândia", qtd_vendas_aa: 1634, qtd_clientes_aa:  1042, qtd_vendas:  1044, qtd_clientes:  708, var_qtd_vendas_aa: -36.11, var_qtd_clientes_aa: -32.05 },
              { idempresa: 36, empresa: "Maringá", qtd_vendas_aa: 1910, qtd_clientes_aa:  1198, qtd_vendas:  1530, qtd_clientes:  811, var_qtd_vendas_aa: -19.90, var_qtd_clientes_aa: -32.30 },
              { idempresa: 38, empresa: "Laranjeiras", qtd_vendas_aa:925, qtd_clientes_aa:  678, qtd_vendas:  1262, qtd_clientes:  765, var_qtd_vendas_aa:  36.43, var_qtd_clientes_aa:   12.83 },
              { idempresa: 41, empresa: "Castelo", qtd_vendas_aa: 1505, qtd_clientes_aa:  776, qtd_vendas:  1928, qtd_clientes:  1115, var_qtd_vendas_aa:  28.11, var_qtd_clientes_aa:   43.69 }
            ]

            this.snackbar.color = 'red';
            if (e.response.data.erro) {
              this.snackbar.text = `${ e.response.data.erro } :[listar]`;
            } else {
              this.snackbar.text = 'Erro, tente recarregar a pagina! :[listar]'
            }

            this.snackbar.status = true;
          })
          .finally(() => {
            this.carregando = false;
            this.carregandoSkeleton = false;
          });
      // Aqui você pode chamar outra função, abrir um modal, etc.
    },

    listar() {
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}dashboard/marketing/tickets/ano/listar`, {
            idfilial: this.busca.idfiliais.join(',') || null,
            ano: this.busca.ano.toString() || null,
          })
          .then((res) => {
            this.dados = res.data;
            this.updateCharts();
          })
          .catch((e) => {
            // Simulação de dados da API
            var apiData
            if (this.busca.ano === "2025") {
              apiData = [{
                qtd_vendas: { janeiro: 10, fevereiro: 20, marco: 30 },
                valor_vendas: { janeiro: 1000, fevereiro: 2000, marco: 3000 },
                ticket_medio: { janeiro: 100, fevereiro: 150, marco: 200 },
                qtd_clientes: { janeiro: 5, fevereiro: 10, marco: 15 },
                media_ticket_clientes: { janeiro: 200, fevereiro: 250, marco: 300 },
              }];
            } else {
              apiData = [{
                qtd_vendas: {
                  janeiro: 10, fevereiro: 20, marco: 30, abril: 25, maio: 40, junho: 35,
                  julho: 50, agosto: 45, setembro: 55, outubro: 60, novembro: 65, dezembro: 70
                },
                valor_vendas: {
                  janeiro: 1000, fevereiro: 2000, marco: 3000, abril: 2500, maio: 4000, junho: 3500,
                  julho: 5000, agosto: 4500, setembro: 5500, outubro: 6000, novembro: 6500, dezembro: 7000
                },
                ticket_medio: {
                  janeiro: 100, fevereiro: 150, marco: 200, abril: 175, maio: 225, junho: 195,
                  julho: 250, agosto: 225, setembro: 275, outubro: 300, novembro: 325, dezembro: 350
                },
                qtd_clientes: {
                  janeiro: 5, fevereiro: 10, marco: 15, abril: 12, maio: 20, junho: 18,
                  julho: 25, agosto: 22, setembro: 28, outubro: 30, novembro: 32, dezembro: 35
                },
                media_ticket_clientes: {
                  janeiro: 200, fevereiro: 250, marco: 300, abril: 280, maio: 350, junho: 320,
                  julho: 400, agosto: 370, setembro: 420, outubro: 450, novembro: 480, dezembro: 500
                }
              }];
            }

            this.dados = apiData[0];

            this.snackbar.color = 'red';
            if (e.response.data.erro) {
              this.snackbar.text = `${ e.response.data.erro } :[listar]`;
            } else {
              this.snackbar.text = 'Erro, tente recarregar a pagina! :[listar]'
            }

            this.snackbar.status = true;
          })
          .finally(() => {
            this.carregando = false;
            this.carregandoSkeleton = false;
          });
    },

    updateCharts() {
      if (this.indicadorSelecionado) {
        const indicador = this.indicadoresNomes.find(
            (f) => f.valor === this.indicadorSelecionado
        ).titulo;
        console.log(indicador)

        this.pieSeries = Object.values(this.meses.map(mes =>  this.dados[this.indicadorSelecionado][mes]))
        this.pieOptions.labels = this.meses;

        this.lineSeries = [{
          name: `${indicador}`,
          data: Object.values(this.meses.map(mes =>  this.dados[this.indicadorSelecionado][mes]))
        }];
        this.lineOptions.xaxis.categories = this.meses;

        this.barSeries = [
          { name: `${indicador}`, data: Object.values(this.meses.map(mes =>  this.dados[this.indicadorSelecionado][mes])) },
        ];
        this.barOptions.xaxis.categories = this.meses;
      }
    },

    async init() {
      // this.busca.idfiliais = this.usuario.idfilial;
      await new Promise(resolve => setTimeout(resolve, 500));
      this.busca.idfiliais = await this.filiaisFiltro.map(item => item.idfilial);
      console.log(typeof `${new Date().getFullYear()}`)
      // this.busca.ano = `${new Date().getFullYear()}`;
      await this.toggleSelectAll();
      await this.listar();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.bordered-col {
  border: 1px solid #ddd !important;
}

.bordered-col .v-card__subtitle{
  background-color: #f4f4f4 !important;
  font-weight: bold !important;
  text-align: center !important;
}

.bordered-table {
  border-collapse: collapse;
}

.bordered-table th,
.bordered-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.bordered-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-align: left;
}

.active-btn {
  background-color: #3D7AA8 !important;
  color: white !important;
}

.active-btn2 {
  background-color: rgba(33, 150, 243, 0.1); /* Azul leve */
  border-color: #3D7AA8 !important; /* Azul do Vuetify */
  color: #3D7AA8 !important; /* Mantém o texto azul */

}

</style>