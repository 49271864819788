<template>
	<VueApexCharts type="pie" :height="height" :options="chartOptions" :series="series"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoPizza",
	components: { VueApexCharts },
	props: ["dataSeries", "labels", "palette", "height"],
	data: () => ({
		series: [],
    chartOptions: {
      chart: {
        type: "pie",
      },
      legend: {
        show: true,
        position: 'bottom', // Coloca as labels na parte inferior
        horizontalAlign: 'center', // Centraliza as labels horizontalmente
      },
      theme: {
        palette: "",
      },
      colors: [
        // Verão (Dezembro - Março)
        // "#FF5733", // Dezembro - Vermelho alaranjado quente
        "#FF8D1A", // Janeiro - Laranja vibrante
        "#FFC300", // Fevereiro - Amarelo solar
        "#FFD700", // Março - Dourado

        // Outono (Março - Junho)
        "#D35400", // Abril - Laranja queimado
        "#A04000", // Maio - Marrom avermelhado
        "#784212", // Junho - Marrom terroso

        // Inverno (Junho - Setembro)
        "#2E86C1", // Julho - Azul frio
        "#1B4F72", // Agosto - Azul escuro
        "#34495E", // Setembro - Cinza azulado

        // Primavera (Setembro - Dezembro)
        "#A569BD", // Outubro - Lilás
        "#E91E63", // Novembro - Rosa vibrante
        // "#2ECC71"  // Dezembro - Verde primavera
        "#FF5733", // Dezembro - Vermelho alaranjado quente
      ],
      labels: [],
      responsive: [
        {
          breakpoint: 700,
          options: {
            legend: {
              show: false,
            },
            chart: {
              type: "pie",
              height: 320,
            },
          },
        },
      ],
      dataLabels: {
        style: {
          fontSize: '18px', // Aumenta o tamanho da fonte dos valores
          fontWeight: 'bold', // Deixa os valores em negrito
          colors: ["#002D56"], // Cor dos valores
          textAnchor: 'middle' // Centraliza o texto
        },
        background: {
          enabled: true,
          borderColor: "#fff",
          opacity: 0.6,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 15, // Move as labels para fora
            minAngleToShowLabel: 5,
          },
        },
      },
    },
	}),
	methods: {
		init() {
			this.series = this.dataSeries;
			this.chartOptions.theme.palette = this.palette;
			this.chartOptions.labels = this.labels;
		},
	},
	created() {
		this.init();
	},
};
</script>
